import React from "react"

import Layout from "../components/layout"
import Title from "../components/title"
import Card from '../components/card'

import cat1 from "../images/cat1.jpg"
import cat2 from "../images/cat2.jpg"
import cat3 from "../images/cat3.jpg"


const cat = () => (
    <Layout>
        <Card style={{ width: '100%', height: '1000px', padding: '20px', marginTop: '30px' }}>
            <Title>Cat</Title>
            <img src={cat1}></img>
            <img src={cat2}></img>
            <img src={cat3}></img>
            {/* <object data="{cat1}"> </object>
            <object data="{cat2}"> </object>
            <object data="{cat3}"> </object> */}

        </Card>
    </Layout>

)

export default cat